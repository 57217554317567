<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="request || !workflows || !groups">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Группа"
                    label-for="workflow_group_slug"
                >
                    <b-form-select
                        id="workflow_group_slug"
                        v-model="form.workflow_group_slug"
                        :options="groups"
                        placeholder="Укажите группу"
                        required
                        :state="errors.workflow_group_slug ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.workflow_group_slug"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Workflow"
                    label-for="workflow_class"
                >
                    <b-form-select
                        id="workflow_class"
                        v-model="form.workflow_class"
                        :options="workflows"
                        placeholder="Укажите workflow"
                        required
                        :state="errors.workflow_class ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.workflow_class"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Шанс"
                    label-for="probability"
                >
                    <b-form-input
                        id="email"
                        v-model="form.probability"
                        type="text"
                        placeholder="Введите шанс"
                        required
                        :state="errors.probability ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.probability"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </b-button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                workflow_group_slug: null,
                workflow_class: null,
                probability: 0,
            },
            errors: {},
            request: false,
            workflows: null,
            groups: null,
        };
    },
    mounted() {
        this.axios.get("/admin_api/chances/selects").then(async ({data}) => {
            this.groups = data.groups.map((group) => {
                return {value: group.slug, text: `${group.name} (${group.slug})`};
            });
            this.workflows = data.workflows.map((workflow) => {
                return {value: workflow.class, text: workflow.class};
            });
        });
    },
    methods: {
        submit(event) {
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;

            this.axios.post("/admin_api/chances", {...this.form})
                .then(async ({data}) => {
                    this.$alertify.success("Запись добавлена");
                    this.$router.push({path: `${data.id}`});
                })
                .catch(async ({response}) => {
                    if (response.status === 422) {
                        this.errors = response.data.errors;
                    }
                    this.$alertify.error(response.data.message);
                })
                .finally(async () => {
                    this.request = false;
                });

        },
    },
};
</script>
